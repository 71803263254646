import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from '@/plugins/SetupI18n'
import BootstrapVue3 from './assets'
import { createMetaManager } from 'vue-meta'
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress'
import { Utils } from '@/utils'
import { pinia } from '@/plugins/pinia'
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss'
import VueQrcodeReader from 'vue-qrcode-reader'
import VueGtag from "vue-gtag-next";

async function bootstrap() {
    const app = createApp(App)
    try {
        app.use(Vue3ProgressPlugin)
        app.use(router)
        app.use(VueGtag, {
            property: {
                id: 'G-NP92FFGDMY',
            },
        })
        await router.isReady()
        app.use(pinia)
        app.use(i18n)
        app.use(createMetaManager())
        app.use(Utils)
        app.use(BootstrapVue3)
        app.use(VueQrcodeReader)
        app.mount('#app')
    } catch (e) {
        throw e
    }
}

bootstrap()
    .then()
    .catch()


