import { StorageService } from '@/plugins/StorageService'
import moment from 'moment-timezone'
import router from '@/router'

export interface StorageToken {
    timestamp: number;
    expired_second: number;
    key: string;
    value: Token;
}

export interface Token {
    access_token: string;
    token_type: string;
    expires_in: number;
    user_id: number;
}

export const getLanguage = (): string => {
    let locale = StorageService.get('locale')
    if (locale) return locale
    if (document) locale = document.getElementsByTagName('html')[0].getAttribute('lang')
    if (!locale) locale = process.env.VUE_APP_LOCALE
    return (locale ?? '').toString()
}

export const setLanguage = (lang?: string | null): void => {
    if (!lang) lang = getLanguage()
    if (lang) {
        document.querySelector('html')?.setAttribute('lang', lang)
        StorageService.set('locale', lang, 60 * 60 * 24 * 7)
        moment.updateLocale(lang, {})
        router.currentRoute.value.params.locale = lang
        void router.push(router.currentRoute.value)
    }
}

export const hasToken = (): boolean => {
    return !!StorageService.get('token')
}

export const getToken = (): Token => {
    return StorageService.get('token', {} as any)
}

export const setToken = (token: Token): any => {
    return StorageService.set('token', token, 60 * 60 * 24 * 7)
}

export const clearToken = (): any => {
    return StorageService.remove('token')
}

/**
 *
 * @returns {string}
 */
export const getFirebaseToken = (): any => {
    return StorageService.get('firebase-token')
}
export const setFirebaseToken = (token: any): any => {
    return StorageService.set('firebase-token', token)
}
export const removeFirebaseToken = (): any => {
    return StorageService.remove('firebase-token')
}
