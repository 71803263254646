import { defineStore } from 'pinia';
import { ApiService } from '@/plugins/ApiService';
import { Constant } from '@/plugins/Constant';
import { i18n } from '@/plugins/SetupI18n';
import { getLanguage, setLanguage } from '@/utils/common';
import router from '@/router';
import { getNameOrderStatus } from '@/utils/global';
import { map, uniqBy } from 'lodash';
import Keycloak from 'keycloak-js';

interface ListFiat {
  id: number,
  code: string,
  name: string,
  icon: string,
  label?: string,
  precision?: string | number,
  symbol?: string
}

interface LanguageObject {
  code: string,
  text: string
}

interface pageDetail {
  id: number;
  title: string;
  content: string;
  meta_desc: string;
  meta_title: string;
  slug: string;
  info: string;
  updated_at: Date;
}

interface AppStoreState {
  loadCompleted: boolean
  keycloak: Keycloak
  language: string
  languageList: Array<LanguageObject>
  languageText?: string

  arrange: string
  fiat: string
  walletText?: string
  walletCode?: string
  listFiat: Array<ListFiat>

  listPaymentMethod: Array<PaymentMethod>
  method: string
  currentMethod?: PaymentMethod

  listCoin: Array<any>
  asset: string,
  coinText?: Object

  showLoadingScreen?: boolean
  isUsdtCurrencies: Object

  listStatus: Array<any>
  is_maintenance?: boolean

  coinTrading: Array<AssetType>
  onlyTimebitPayment?: boolean,
  enable2FAForBuySell?: boolean,
  enable2FAForCreateAdv?: boolean,
  enable2FAForQuickBuySell?: boolean,
  enable2FAForRelease?: boolean,
  enable2FAForUpdateAdv?: boolean,
  enableQuickConform?: boolean,
  enableCodeEmail?: boolean,
  enableCodeOtp?: boolean,
  disableClientLoginRoute?: boolean,
  detailPage: pageDetail | null,
  walletUrl: string,
  defaultAsset: string,
  defaultFiat: string,
  widget: Widget | null,
  botApiUrl: string | null,
  isAndroid?: boolean,
  isIos?: boolean,
  mobileAppUrl?: string,
  isMobileDevice: boolean,
  maxPaymentMethod?: string | number
}

interface AppStoreAction {
  changeLanguage(locale: string, refreshPage?:boolean ): void;

  loadSetting(disableChangeLanguage?: boolean): Promise<void>;

  changeFiat(fiat: string): void;

  changeArrange(arrange: string): void;

  getListPaymentMethod(): Promise<void>;

  changePaymentMethod(method: string): void;

  changeCoin(asset: string): void;

  setLoadingScreen(status: boolean): void;

  getPages(payload?: any): Promise<void>;
}

interface AppStoreGetter {

  getCoins(): Array<string>;
}

export interface AppStore extends AppStoreState, AppStoreAction, AppStoreGetter {
}

export const useAppStore = defineStore('app', {
  state(): AppStoreState {
    return {
      loadCompleted: false,
      keycloak: new Keycloak({
        url: 'https://u2id.io/auth/',
        realm: 'u2u',
        clientId: 'u2u-otc',
      }),
      language: getLanguage(),
      languageList: [],

      fiat: 'VND',
      listFiat: [],

      arrange: '',

      listPaymentMethod: [],
      method: 'ALL',
      listCoin: [],
      asset: 'TRX_USDT',
      showLoadingScreen: false,
      isUsdtCurrencies: [],
      listStatus: [],
      is_maintenance: false,

      coinTrading: [],
      onlyTimebitPayment: false,
      enable2FAForBuySell: false,
      enable2FAForCreateAdv: false,
      enable2FAForQuickBuySell: false,
      enable2FAForRelease: false,
      enable2FAForUpdateAdv: false,
      enableQuickConform: false,
      enableCodeEmail: false,
      enableCodeOtp: false,
      disableClientLoginRoute: false,
      detailPage: null,
      walletUrl: '',
      defaultAsset: '',
      defaultFiat: '',
      widget: null,
      botApiUrl: null,
      isAndroid: false,
      isIos: false,
      mobileAppUrl: '',
      isMobileDevice: false,
      maxPaymentMethod: 0,
    };
  },
  getters: {
    languageText(): string {
      const lang = this.languageList.find(v => this.language == v.code);
      if (lang) return lang.text;
      return '';
    },
    walletText(): string {
      const fiat = this.listFiat.find(v => this.fiat == v.code);
      if (fiat?.name) return fiat.name;
      if (fiat?.code === 'VND' || fiat?.code === 'VNDT') return fiat.code + '-đ';
      if (fiat?.code) return fiat.code;
      return '';
    },
    walletCode(): string {
      const fiat = this.listFiat.find(v => this.fiat == v.code);
      if (fiat?.icon) return fiat.icon;
      return '';
    },
    currentMethod(): Object {
      const method = this.listPaymentMethod.find(v => this.method == v.code);
      if (method) return method;
      return {};
    },
    coinText(): Object {
      const asset = this.listCoin.find(v => this.asset == v.code);
      if (asset) return asset;
      return {};
    },
    getCoins(): any {
      return this.listCoin;
    },
  },
  actions: {
    async setupLanguages(languages: Array<LanguageObject>) {
      for (const language of languages) {
        const data = await import(`@/locales/${language.code}.json`);
        i18n.global.setLocaleMessage(language.code, {
          label: language.text,
          ...data,
        });
      }
    },

    async loadSetting(disableChangeLanguage?:boolean) {
      try {
        this.showLoadingScreen = true;
        // this.changeLanguage(settings?.locale)
        const currentRoute = router.currentRoute.value;
        if (currentRoute.params && currentRoute.params.locale) {
          setLanguage(currentRoute.params.locale.toString());
        }

        const response = await ApiService.instance().axios.get(Constant.GLOBAL_SETTING);

        this.is_maintenance = response.data?.is_maintenance;
        const settings = response.data.settings;
        const languages = settings?.languages;
        const languageResults = [];
        for (const languagesKey in languages) {
          languageResults.push({ code: languagesKey, text: languages[languagesKey] });
        }
        if (languageResults.length) {
          await this.setupLanguages(languageResults);
        }
        this.languageList = languageResults;
        if (!disableChangeLanguage) this.changeLanguage(getLanguage());


        const currencies = settings.currencies;
        const currencyResults = [];
        for (const currency in currencies) {
          currencyResults.push({
            code: currencies[currency].code,
            name: currencies[currency].label,
            icon: currencies[currency].code,
            id: currencies[currency].id,
            label: currencies[currency].label ?? currencies[currency].code,
            precision: currencies[currency].precision,
            symbol: currencies[currency].symbol,
            image_url: currencies[currency].image_url || currencies[currency].avatar_url,
          });
        }

        this.listFiat = currencyResults;
        this.listCoin = settings.coinList;
        this.isUsdtCurrencies = settings.isUsdtCurrencies;

        this.onlyTimebitPayment = settings.onlyTimebitPayment;
        this.coinTrading = settings.coinTradingList;
        this.enable2FAForBuySell = settings.enable2FAForBuySell;
        this.enable2FAForCreateAdv = settings.enable2FAForCreateAdv;
        this.enable2FAForQuickBuySell = settings.enable2FAForQuickBuySell;
        this.enable2FAForRelease = settings.enable2FAForRelease;
        this.enable2FAForUpdateAdv = settings.enable2FAForUpdateAdv;
        this.enableQuickConform = settings.enableQuickConform;
        this.enableCodeOtp = settings.enableCodeOtp;
        this.enableCodeEmail = settings.enableCodeEmail;
        this.disableClientLoginRoute = settings.disableClientLoginRoute;
        this.walletUrl = settings.walletUrl.toString();
        this.defaultAsset = settings.coin ? settings.coin.toString() : 'TRX_USDT';
        this.defaultFiat = settings.currency ? settings.currency.toString() : 'VND';
        this.widget = settings.widget;
        this.botApiUrl = settings.botApiUrl;
        this.isAndroid = settings.isAndroid;
        this.isIos = settings.isIos;
        this.maxPaymentMethod = settings.maxPaymentMethod;

        if (this.isAndroid) {
          this.mobileAppUrl = settings.mobileAppUrl.android;
        } else if (this.isIos) {
          this.mobileAppUrl = settings.mobileAppUrl.ios;
        }
        this.isMobileDevice = settings.isMobileDevice;

        this.listStatus = map(settings.orderStatus, (v) => {
          if (!v) return;
          return {
            value: v,
            text: getNameOrderStatus(v),
          };
        });
        this.listPaymentMethod.push({
          code: 'ALL',
          name: i18n.global.t('theme/trading.all_payments'),
        });
      } finally {
        this.loadCompleted = true;
        this.showLoadingScreen = false;
      }
    },

    async changeLanguage(locale: string,refreshPage?:boolean) {
      this.language = locale;
      if (i18n.global) {
        i18n.global.locale.value = locale;
      }
      setLanguage(locale);
      const to = router.currentRoute;
      const url = router.resolve({
        ...to,
        params: {
          // @ts-ignore
          ...to.params,
          locale: locale,
        },
      });
      // @ts-ignore
      console.log(to.fullPath);
      console.log(url.fullPath);
      // @ts-ignore
      if (refreshPage && to.fullPath !== url.fullPath) {
        await router.push({ ...url, force: true });
      }
    },

    async getListPaymentMethod() {
      try {
        const response = await ApiService.instance().axios.get(Constant.PAYMENT_METHOD_LIST);
        if (response && response.status) {
          this.listPaymentMethod = [{
            code: 'ALL',
            name: i18n.global.t('theme/trading.all_payments'),
          }, ...response.data];
        }
      } catch (e) {
      }
    },

    changeFiat(fiat: string) {
      this.fiat = fiat;
    },

    changeArrange(arrange: string) {
      this.arrange = arrange;
    },

    changePaymentMethod(method: string) {
      this.method = method;
    },

    changeCoin(asset: string) {
      this.asset = asset;
    },

    setLoadingScreen(status?: boolean) {
      this.showLoadingScreen = status;
    },

    async getPages(payload?: any) {
      const response = await ApiService.instance().axios.post(Constant.PAGES, payload);
      if (response && response.status) {
        this.detailPage = response.data;
      }
    },
  },
});
