import { defineStore } from 'pinia'
import { ApiService } from '@/plugins/ApiService'
import { Constant } from '@/plugins/Constant'
import { filter, isArray, size } from 'lodash'
import { clearToken, setToken } from '@/utils/common'
import { AppStore, useAppStore } from '@/stores/appStore'

const md5 = require('js-md5')

interface PayloadUserDetail {
    slug: string | null;
}

interface PayloadMerchantDetail {
    id: string | number;
}

interface PayloadNotification {
    offset?: string | number;
    id?: string | number;
    notification?: string | number;
}

interface PayLoadReview {
    id?: string | number;
    page?: string | number;
    perPage?: string | number;
    type?: string | number;
}

interface PayloadConfigPayment {
    code?: string | null;
    id?: string | number;
    data?: any;
}

interface PayloadDeleteUserPayment {
    id?: string | number;
}

interface PaymentMethodDetail {
    code?: string | null;

}

interface PayloadAddBlackList {
    email: string | null;
    note?: string | null;
}

interface PayloadRemoveBlackList {
    id?: string | number;
}

interface UserStoreState {
    userDetail: UserModule.UserDetail | null;
    buyAdvList: Array<AdvModule.AdvItem>;
    sellAdvList: Array<AdvModule.AdvItem>;
    trade_type?: string;
    pagination?: Pagination;
    balanceAsset: UserModule.ListBalanceAsset | null;
    balanceFiat: UserModule.ListBalanceFiat | null;
    unread?: number;
    isScroll: boolean;
    balanceAssetAvailable: Array<BalanceAsset>;
    balanceFiatAvailable: Array<BalanceFiat>;
    userPaymentMethod: Array<any>;

    userProfile: UserModule.UserInfo | null;
    activityList: Array<ActivityItem>;
    reviewListPartner: Array<ReviewItem>;
    totalPartner: number | string;
    reviewListRecent: Array<ReviewItem>;
    totalRecent: number | string;
    merchantDetail: UserModule.Merchant | null;

    detailPaymentMethod: UserModule.DetailPaymentMethod | null;
    blackList: Array<UserModule.BlackItem>;

    code_email?: string | number | null;
}

interface UserStoreAction {
    getUserDetail(payload: PayloadUserDetail): Promise<void>;

    getMerchantDetail(payload: PayloadMerchantDetail): Promise<any>;

    getAdvList(payload?: Object): Promise<any>;

    getBalanceAsset(payload ?: Object): Promise<void>;

    getBalanceFiat(payload ?: Object): Promise<void>;

    getNotification(payload ?: PayloadNotification): Promise<Array<NotificationItem>>;

    getDetailNotification(payload ?: PayloadNotification): Promise<NotificationItem | null>;

    readNotification(payload ?: PayloadNotification): Promise<boolean>;

    readAllNotification(): Promise<boolean>;

    getUserPaymentMethod(): Promise<any>;

    getUserProfile(): Promise<any>;

    getActivityList(id: string | number): Promise<void>;

    getReviewList(payload: PayLoadReview): Promise<void>;

    logout(): Promise<any>;

    addPaymentMethod(payload?: PayloadConfigPayment): Promise<any>;

    deleteUserPaymentMethod(payload?: PayloadDeleteUserPayment): Promise<any>;

    getDetailPaymentMethod(payload?: PaymentMethodDetail): Promise<void>;

    getDetailUserPaymentMethod(payload?: PayloadDeleteUserPayment): Promise<void>;

    getUserBlackList(payload?: any): Promise<void>;

    addUserBlackList(payload?: PayloadAddBlackList): Promise<any>;

    removeUserBlackList(payload?: PayloadRemoveBlackList): Promise<any>;

    getCodeEmail(payload: any): Promise<any>;

    getCodeOtp(payload: any): Promise<any>;

    login(payload: AuthModule.FormLogin): Promise<any>;

    verify(payload: AuthModule.FormVerify): Promise<any>;

    createMerchantName(payload?: any): Promise<any>;
}

export interface UserStore extends UserStoreState, UserStoreAction {
}

export const useUserStore = defineStore('user', {
    state(): UserStoreState {
        return {
            userDetail: null,
            buyAdvList: [],
            sellAdvList: [],
            trade_type: '',
            pagination: {},
            balanceAsset: null,
            balanceFiat: null,
            unread: 0,
            isScroll: true,
            balanceAssetAvailable: [],
            balanceFiatAvailable: [],
            userPaymentMethod: [],
            userProfile: null,
            activityList: [],
            reviewListPartner: [],
            reviewListRecent: [],
            totalPartner: 0,
            totalRecent: 0,
            merchantDetail: null,
            detailPaymentMethod: null,
            blackList: [],
        }
    },
    getters: {},
    actions: {
        async getUserDetail(payload: PayloadUserDetail) {
            try {
                const response = await ApiService.instance().axios.get(Constant.USER_DETAIL + '?slug=' + payload.slug)
                if (response && response.status) {
                    this.userDetail = response.data
                }
            } catch (e: any) {
                return Promise.reject(e.response.data)
            }
        },

        async getMerchantDetail(payload ?: PayloadMerchantDetail) {
            try {
                const response = await ApiService.instance().axios.post(Constant.MERCHANT_DETAIL + `/${payload}`)
                if (response && response.status) {
                    this.merchantDetail = response.data
                    return response.data
                }
            } catch (e: any) {
                return Promise.reject(e.response.data)
            }
        },

        async getAdvList(payload: Object) {
            try {
                const response = await ApiService.instance().axios.post(Constant.MERCHANT_ADV_LIST, payload)
                if (response && response.status) {

                    if (response.data.buyList) {
                        this.buyAdvList = response.data.buyList
                    }

                    if (response.data.sellList) {
                        this.sellAdvList = response.data.sellList
                    }
                }
            } catch (e: any) {
                return Promise.reject(e.response.data)
            }
        },

        async getBalanceAsset(payload?: Object) {
            const response = await ApiService.instance().axiosAuthorization.get(Constant.BALANCE_ASSET, payload)
            if (response && response.status) {
                if (response.data && response.data.length && isArray(response.data)) {
                    this.balanceAssetAvailable = response.data // filter(response.data, v => v.free > 0);
                    this.balanceAsset = response.data.reduce((acc, item) => {
                        acc[item.asset] = item
                        return acc
                    }, {})
                }
            }
        },

        async getBalanceFiat(payload?: Object) {
            const appStore: AppStore = useAppStore()
            const currencies = appStore.listFiat
            this.balanceFiatAvailable = []
            this.balanceFiat = {};
            for (const currency of currencies) {
                this.balanceFiatAvailable.push({
                    code: currency.code,
                    free: 0,
                    freeze: 0,
                    // @ts-ignore
                    avatar: currency['image_url'],
                    is_asset: false,
                    position_p2p: 1,
                    label: currency.code,
                })
                // @ts-ignore
                this.balanceFiat[currency.code] = {
                    code: currency.code,
                    free: 0,
                    freeze: 0,
                    // @ts-ignore
                    avatar: currency['image_url'],
                    is_asset: false,
                    position_p2p: 1,
                    label: currency.code,
                }
            }

            /*   return;
               const response = await ApiService.instance().axiosAuthorization.get(Constant.BALANCE_FIAT, payload);
               if (response && response.status) {
                   if (response.data && response.data.length && isArray(response.data)) {
                       this.balanceFiatAvailable = filter(response.data, v => {
                           if (v.code && v.code.toString() === 'VND') {
                               return v;
                           }
                           return v.free > 0;
                       });
                       this.balanceFiat = response.data.reduce((acc, item) => {
                           acc[item.code] = item;
                           return acc;
                       }, {});
                   }
               }*/
        },

        async getNotification(payload?: PayloadNotification): Promise<Array<NotificationItem>> {
            try {
                const response = await ApiService.instance().axiosAuthorization.get(Constant.NOTIFICATION_LIST + `?offset=${payload?.offset}`)
                if (response && response.status) {
                    if (response.data.notifications && response.data.notifications.length) {
                        this.unread = response.data.unread
                        return response.data.notifications
                    } else {
                        this.isScroll = false
                    }
                }
                return []
            } catch (e: any) {
                return e.response.data.data
            }
        },

        async getDetailNotification(payload ?: PayloadNotification): Promise<NotificationItem | null> {
            try {
                const response = await ApiService.instance().axiosAuthorization.get(Constant.NOTIFICATION_LIST + `?notification=${payload?.notification}`)
                if (response && response.status) {
                    if (size(response.data.notification) > 0) {
                        this.unread = response.data.unread
                        return response.data.notification
                    }
                    return null
                } else {
                    return null
                }
            } catch (e) {
                return null
            }
        },

        async readNotification(payload: PayloadNotification) {
            try {
                const response = await ApiService.instance().axiosAuthorization.get(Constant.NOTIFICATION_READ + `?id=${payload?.id}`)
                return !!(response && response.status)
            } catch (e) {
                return false
            }
        },

        async readAllNotification() {
            const response = await ApiService.instance().axiosAuthorization.get(Constant.NOTIFICATION_READ_ALL)
            this.unread = 0
            return !!(response && response.status)
        },

        async getUserPaymentMethod(): Promise<any> {
            try {
                const response = await ApiService.instance().axiosAuthorization.post(Constant.USER_PAYMENT_METHOD)
                if (response && response.status) {
                    this.userPaymentMethod = response.data
                    return response.data
                } else {
                    return null
                }
            } catch (e) {
                return Promise.reject(e)
            }
        },

        async getUserProfile(): Promise<any> {
            try {
                const response = await ApiService.instance().axiosAuthorization.get(Constant.USER_PROFILE)
                if (response && response.status) {
                    this.userProfile = response.data
                    return response.data
                } else {
                    return null
                }
            } catch (e: any) {
                return Promise.reject(e)
            }
        },

        async getActivityList(id: string | number) {
            const response = await ApiService.instance().axios.get(Constant.USER_ACTIVITIES + '?id=' + id)

            if (response && response.status) {
                this.activityList = response.data
            }
        },

        async getReviewList(payload: PayLoadReview) {
            const response: any = await ApiService.instance().axiosAuthorization.get(Constant.USER_REVIEW + `?id=${payload.id}` + `&page=${payload.page}` + `&perPage=${payload.perPage}` + `&type=${payload.type}`)

            if (response) {
                if (payload.type == 0) {
                    this.reviewListPartner = response.data
                    this.totalPartner = response.total
                } else if (payload.type == 1) {
                    this.reviewListRecent = response.data
                    this.totalRecent = response.total
                }

                this.pagination = Object.assign({}, {
                    currentPage: response.page,
                    total: response.total,
                })
            }
        },

        async addPaymentMethod(payload?: PayloadConfigPayment): Promise<any> {
            try {
                return await ApiService.instance().axiosAuthorization.post(Constant.USER_PAYMENT_METHOD_ADD, payload)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async deleteUserPaymentMethod(payload?: PayloadDeleteUserPayment): Promise<any> {
            try {
                return await ApiService.instance().axiosAuthorization.delete(Constant.USER_PAYMENT_METHOD_DELETE + `/${payload?.id}`)

            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async getDetailPaymentMethod(payload?: PaymentMethodDetail) {
            const response = await ApiService.instance().axiosAuthorization.post(Constant.PAYMENT_METHOD_DETAIL + `/${payload?.code}`)
            if (response && response.status) {
                this.detailPaymentMethod = response.data
            }
        },

        async getDetailUserPaymentMethod(payload?: PayloadDeleteUserPayment) {
            const response = await ApiService.instance().axiosAuthorization.post(Constant.USER_PAYMENT_METHOD_DETAIL + `/${payload?.id}`)
            if (response && response.status) {
                this.detailPaymentMethod = response.data
            }
        },

        async getUserBlackList(payload?: any) {
            let query = `?page=${payload.page}` + `&perPage=${payload.perPage}`
            if (payload?.date_from) {
                query += `&date_from=${payload.date_from}`
            }
            if (payload?.date_to) {
                query += `&date_to=${payload.date_to}`
            }
            if (payload?.email) {
                query += `&email=${encodeURIComponent(payload.email)}`
            }
            const response = await ApiService.instance().axiosAuthorization.get(Constant.USER_BLACK_LIST + query)

            if (response && response.status) {
                this.blackList = response.data.data

                this.pagination = Object.assign({}, {
                    currentPage: response.data.page,
                    total: response.data.total,
                })
            }
        },

        async addUserBlackList(payload?: PayloadAddBlackList): Promise<any> {
            try {
                return await ApiService.instance().axiosAuthorization.post(Constant.USER_BLACK_LIST_ADD, payload)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async removeUserBlackList(payload?: PayloadRemoveBlackList): Promise<any> {
            try {
                return await ApiService.instance().axiosAuthorization.post(Constant.USER_BLACK_LIST_REMOVE, payload)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async getCodeEmail(payload?: any): Promise<any> {
            try {
                return await ApiService.instance().axios.get(Constant.SEND_CODE + `?type=email&token=${payload.token}`)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async getCodeOtp(payload?: any): Promise<any> {
            try {
                return await ApiService.instance().axios.get(Constant.SEND_CODE + `?type=phone&token=${payload.token}`)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async login(payload: AuthModule.FormLogin) {
            try {
                const response = await ApiService.instance().axios.post(Constant.AUTH_LOGIN, {
                    ...payload,
                    password: md5(md5(payload.password).toString()).toString(),
                })
                if (response && response.status) {
                    return response.data
                }
                return Promise.reject(response)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async verify(payload: AuthModule.FormVerify) {
            try {
                const response = await ApiService.instance().axios.post(Constant.AUTH_VERIFY, payload)
                if (response && response.status) {
                    const dataToken = response.data?.token
                    const token = Object.assign({}, {
                        access_token: dataToken?.access_token,
                        token_type: dataToken?.token_type,
                        expires_in: dataToken?.expires_in,
                        user_id: dataToken?.user_id,
                    })
                    setToken(token)
                    await this.getUserProfile()
                    return response
                }
                return Promise.reject(response)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },

        async logout(): Promise<any> {
            try {
                const response = await ApiService.instance().axiosAuthorization.post(Constant.AUTH_LOGOUT)
                if (response && response.status) {
                    this.userProfile = null
                    clearToken()
                    return response
                }
            } catch (e) {
                return e
            }
        },

        async createMerchantName(payload?: any): Promise<any> {
            try {
                const response = await ApiService.instance().axiosAuthorization.post(Constant.CREATE_MERCHANT_NAME, payload)
                if (response && response.status) {
                    await this.getUserProfile()
                    return response.data
                }
                return Promise.reject(response)
            } catch (e: any) {
                return Promise.reject(e.response.data ?? e)
            }
        },
    },
})
