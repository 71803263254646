import MainLayout from '@/views/layouts/MainLayout.vue'
import { Helpers } from '@/plugins/Helpers'


export default {
    path: '',
    component: MainLayout,
    children: [
        {
            path: 'overview',
            name: 'overview.index',
            component: Helpers.lazyload('overview/index'),
            meta: { auth: true}
        },
        {
            path: 'overview/payment',
            name: 'overview.payment',
            component: Helpers.lazyload('overview/index'),
            meta: { auth: true}
        },
        {
            path: 'overview/payment/add/:code',
            name: 'overview.payment.add',
            component: Helpers.lazyload('overview/index'),
            meta: { auth: true}
        },
        {
            path: 'overview/payment/edit/:id',
            name: 'overview.payment.edit',
            component: Helpers.lazyload('overview/index'),
            meta: { auth: true}
        },
        {
            path: 'overview/blacklist',
            name: 'overview.blacklist',
            component: Helpers.lazyload('overview/index'),
            meta: { auth: true}
        },
        {
            path: 'overview/settings',
            name: 'overview.settings',
            component: Helpers.lazyload('overview/index'),
            meta: { auth: true}
        },
        {
            path: 'overview/transactions',
            name: 'overview.transactions',
            component: Helpers.lazyload('overview/index'),
            meta: { auth: true}
        },
    ]
}
